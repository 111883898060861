.thumbnail {
    &-video {
        position: relative;

        .thumbnail-overlay {
            position: relative;
            background: $color-black;

            img {
                width: 100%;
            }
        }
        .thumbnail-icon {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;

            background: url(../images/__cgenial/icon_play_grey.png) no-repeat center center transparent;
            background-size: 80px auto;
            opacity: 0.8;

            &:hover, &:focus {
                background-image: url(../images/__cgenial/icon_play_red.png);
                opacity: 1;
            }

            .thumbnail-icon-play {
                display: none;
                position: absolute;
                top: 50%;
                left: 50%;
                margin: -28px 0 0 -40px;
                width: 80px;
                height: auto;
            }
        }
    }
    &-photo {
        
    }
}

img, svg {
    max-width: 100%;
    fill: currentColor;
}

.photo-badge {
    width: 150px;
    height: 150px;
    border-radius: 75px;
    overflow: hidden;
    color: $color-tundora;
    background: no-repeat center center $color-white;
    background-size: cover;
}

figure {
    overflow: hidden;
}