.social-networks {
    li {
        display: inline-block;
        width: 40px;
        height: 40px;
        padding-left: 10px;
        margin-right: 1*$base-spacing;

        @media (min-width:1200px) and (max-width:1439px) {
            margin-right: 0.333*$base-spacing;  
        }

        a {
            /* use !important to prevent issues with browser extensions that change fonts */
            font-family: 'icon-cgenial' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 0;
            /* Better Font Rendering =========== */
            text-rendering:optimizeLegibility;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            
            display: inline-block;

            &>i {
                color: $color-white !important;
            }

            &.facebook {
                &::before {
                    color: #3b5999 !important;    
                }
            }

            &.twitter {
                &::before {
                    color: #55acef !important;    
                }
            }

            &.linkedin {
                &::before {
                    color: #006599 !important;    
                }
            }

            &.youtube {
                &::before {
                    color: #df2f2f !important;    
                }
            }

            &.flickr {
                &::before {
                    color: $color-white !important;    
                }
            }

            &.instagram {
                &>i {
                    color: $color-tundora !important;
                }

                &::before {
                    color: $color-white !important;    
                }
            }

            &:hover, &:focus {
                &::before {
                    color: $link-hover-color !important;  
                }
                
                i, i span {
                    &::before {
                        color: $color-white !important;
                    }
                }
            }
        }
    }
}