html {
  font-size: 10px;
}

html,
body {
  margin: 0;
  padding: 0;
}

abbr {
  border-bottom: 1px dotted inherit;
  cursor: help;

  &.required-help {
    border-bottom: 0;
    text-decoration: none;
  }
}

em {
  font-style: italic;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 1em 0;

  &:first-child {
    margin-top: 0;
  }

  + * {
    margin-top: 0;
  }
}

img {
  border: 0;
}

p {
  margin: 1em 0 0;
}

strong {
  font-weight: bold;
}


// Eléments de liste
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}


// Eléments de formulaire
button,
input,
select,
textarea {
  color: inherit;
  font: inherit;
  line-height: 1.2;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

select {
  * {
    font-family: inherit;
    line-height: inherit;
  }
}

button,
input,
select {
  vertical-align: middle;
}

pre {
  margin: 1.5rem 0;
  padding: 1.5rem;
  border:1px solid $color-concrete;
  background: $color-alabaster;
}