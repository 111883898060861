.card {
    &.operation, &.demande {
        
            @include media-breakpoint-up(md) {
                .btn + .btn {
                    margin-top: 1*$base-spacing;
                }
            }

            @include media-breakpoint-down(xs) {
                .btn {
                    margin-top: 1*$base-spacing;
                }
            }
    }
}

.espace-perso-actions, 
.intervenants-description {
    margin-bottom: 1*$base-spacing;
}

.module-inview-espace_perso {
    .description {
        margin-top: 0;
        margin-bottom: 2*$base-spacing;
    }
}