iframe {
    //pointer-events: none;
    max-width: 100%;
}

img, svg {
    //pointer-events: none;
    max-width: 100%;
}

.no-padding {
    padding: 0 !important;
}

.no-margin {
    margin: 0 !important;
}

.cgenial-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;

    display: flex;
    justify-content: center;
    align-items: center;

    background: $color-white-alpha;
}

.bubble {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icon-cgenial' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 0;
    /* Better Font Rendering =========== */
    text-rendering:optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-size: 40px;
    position: relative;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;

    &:hover, &:focus {
        text-decoration: none;
    }

    &>* {
        position: relative;
        display: inline-block;
        z-index: 1;
        margin: 0;
        font-family: 'Oswald', 'Arial', sans-serif;
        font-size: 1.8rem;
        line-height: 1.1;
        font-weight: 400;
        letter-spacing: 2.1px;

        strong {
            display: block;
            font-size: 5rem;
            font-weight: 400;
            letter-spacing: 3.75px;
        }
    }

    &::before {
        content: $icon-cgenial-simple;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -20px;
        z-index: 0;
        display: block;
        width: inherit;
        height: inherit;
    }

    &-xs {
        font-size: 16px; 

        &::before {
            margin-left: -9px;
        } 

        &>* {
            font-size: 1.1rem;
        }
    }

    &-sm {
        font-size: 30px;   

        &::before {
            margin-left: -15px;
        } 

        &>* {
            font-size: 1.3rem;
        }
    }

    &-md {
        font-size: 100px;  

        &::before {
            margin-left: -50px;
        } 

        &>* {
            font-size: 1.3rem;
        }
    }

    &-lg {
        font-size: 150px;  

        &::before {
            margin-left: -75px;
        } 

        &>* {
            font-size: 2.4rem;
        }
    }

    &-xl {
        font-size: 230px;  

        &::before {
            margin-left: -115px;
        } 

        &>* {
            font-size: 2.8rem;
        }
    }
}

.quote {
    position: relative;
    padding-left: 48px;
    margin-left: -80px;
    svg {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 33px;
        height: 25px;
    }

    &-grey {
        &::before {
            // @TMP ... Waiting grey image
            mix-blend-mode: difference;
        }
    }
}

.category-pee {
    display: inline-block;

    .category-pee--image {
      display: flex;
      width: 32px;
      height: 32px;
      align-items: center;
      justify-content: center;
      margin: 0 auto 5px;

      svg, svg * {
          fill: currentColor;
      }
    }

    .category-pee--caption {
      text-transform: uppercase;
      font: $font-size-h6 'Oswald', 'Arial', sans-serif;
      word-wrap: break-word;
    }
  }

.list-scrollable {
    overflow-y: scroll;
  }

.page-header {
    min-height: 15vw;
    display: flex;
    align-items: center;

    background-position: 100% 10%; //top right;
    background-repeat: no-repeat;
    background-size: cover;
    //background-blend-mode: multiply; 

    @media (min-width: 992px) {
        background-size: 70% auto; //50% auto;    
    }

    .item-content {
        padding: 60px 1rem;

        @media (min-width: 992px) {
            width: 50%;   
        }
    }

    &-profile {
        .item-content {
            width: 100%;
            
            .username {
                margin-top: 2*$base-spacing;
            }
        }    

        .photo-badge {
            color: $color-silver-chalice;
            background-color: transparent;
        }        
    }

    &.has-bg-image {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            background-color: inherit;
            z-index: 1;
            opacity: 0.85;

            @media (min-width: 992px) {
                width: 70%;
                opacity: 1;    
            }
        }

        .item-content {
            position: relative;
            z-index: 2;
        }
    }

    &.has-nav {
        min-height: auto;
        display: static;

        .item-content {
            padding-bottom: 2*$base-spacing;
        }

        .nav {
            position: relative;
            z-index: 2;    
            width: 100%;
        }
    }

    h1, .h1 {
        margin: 0;
    }
}


.ui-accordion {
    .ui-accordion-header {
        .icon-legacy {
            float: right;
            margin-top: -.8rem;
            position: absolute;
            right: .5em;
            top: 50%;
            transform: rotate(180deg);
            transition: transform 0.3s;
        }

        &.ui-state-active {
            .icon-legacy {
                transform: rotate(90deg);
            }
        }
    }
}

.filter_form {
    margin-top: 6rem;
}

.cards-partners {
    padding-top: 8px;
    border-radius: 8px;
    background: $color-concrete;

    > div {
        padding-right: 8px;
        padding-left: 8px;
        margin-bottom: 8px;
    }

    .card {
        border-radius: 4px;
        background: $color-white; 
        text-align: center; 
        
        a.d-block {
            border-radius: 4px;
            border: 2px solid $color-white;
            text-decoration: none;

            &:hover, &:focus {
                border-color: $color-silver-chalice;
            }
        }

        .img-block {
            display: block;
            width: 100%;
            height: 150px;
            overflow: hidden;

            img {
                max-width: 100%;
                height: auto;
            }
        }

        .item-title {
            color: $color-tundora;
        }

        .img-block + .item-title {
            margin-top: $base-spacing;
        }
    }
}

.cards-team {
    .card {
        &.bg-theme {
            background: #F3F3F3;
            color: #4A4949;
        }
        
        h3 {
            color: #fff;
            background: #f39222;
            margin: 0;
            padding: 0.5em 1em;
            text-align: center;
            font-size: 1em;
        }

        .figure {
            height: 250px;    
        }

        .item-footer {
            .item-desc {
                color: #4A4949;
                padding: 0.5em;;
                p {
                    color: #4A4949;
                    line-height: 1.2;
                    font-size: 0.9em;
                }
            }
        } 
    }
}

.infograph {
    img {
        width: 100% !important;
        height: auto !important;
    }
}

.after_content {
    margin-top: 6*$base-spacing;

    .module.card {
        min-height: 320px;
        display: flex;
        align-items: center;
    }

    @include media-breakpoint-down(lg) {
        [class^="col-"], [class*=" col-"] {
            margin-bottom: 1*$base-spacing;
        }
    }
}

.faq-answer {
    display: block;
    padding: $base-spacing 2*$base-spacing;
    margin: $base-spacing 0; 
    border-radius: 2px;
    background: $color-white;

    > p:first-child {
        margin-top: 0;
    }

    > p:last-child {
        margin-bottom: 0;
    }
}