.cgenial-modal {
  min-width: 50%;
  max-width: 600px;
  padding: 0;
  background: none;

  &.pee, &.itdlc, &.first_login, &.availability {
    max-width: 1200px;  
  }

  .modal-content {
    .modal-header {
      padding: 2*$base-spacing;
      border: none;
      border-radius: 0;

      .padding {
        padding: 2*$base-spacing;  
      }

      > div {
        display: block;
        width: 100%;
      }

      .has-bg-image {
        background-position: right top;
        background-repeat: no-repeat;
        background-size: cover;

        margin: 0 -2*$base-spacing 0 0;
      }

      h1, h2, h3, h4, h5, h6,
      .h1, .h2, .h3, .h4, .h5, .h6 {
        display: block;
        width: 100%;
        margin: 0;
      }
    }

    .modal-body {
      padding: 2*$base-spacing;
      border: none; 
      border-radius: 0; 

      .padding {
        padding: 2*$base-spacing;  
      }

      > div {
        display: block;
        width: 100%;
      }

      .text-small {
        p {
          @extend .text-small;
        }
      }
    }

    .modal-footer {
      padding: 2*$base-spacing;
      border: none; 
      border-radius: 0;

      .padding {
        padding: 2*$base-spacing;  
      }

      > div {
        display: block;
        width: 100%;
      }
      
      @include media-breakpoint-up(sm) {
        .btn + .btn {
          margin-left: 6*$base-spacing;
        }
      }
    }
  }  

  &.availability {
    .select-wrapper {
      &::after {
        content: none;
      }
    }
  }
  
  &.pee {
    &.ope {
      .modal-header {
        .location {
          margin-top: 0;
        }
      }

      .modal-content {
        .col-left {
          @include media-breakpoint-up(md) {
            .col-md-10 {
              padding-left: 0;
            }

            .col-md-2 {
              padding-right: 0;
            }
          }

          @include media-breakpoint-down(sm) {
            .col-md-10 {
              order: 2;
            }

            .col-md-2 {
              order: 1;
              text-align: center !important;
            }
          }

          h2, .h2,
          h3, .h3,
          h4, .h4,
          h5, .h5,
          h6, .h6
          {
            margin-bottom: $base-spacing;
            text-transform: none;
            font-weight: 400;
          }

          .block {
            margin-bottom: 3*$base-spacing;
          }

          .category-pee {  
            .category-pee--image {
              width: 64px;
              height: 64px;

              img {
                max-width: 32px;
              }
            }
          }

          .quote {
            font-style: italic;
            .icon-quote {
              fill: $color-la-rioja;
            }
          }
        }

        .col-right {
          h2, .h2,
          h3, .h3,
          h4, .h4,
          h5, .h5,
          h6, .h6
          {
            margin-bottom: 2*$base-spacing;
            text-transform: none;
            font-weight: 400;
          }
          
          .state {
            display: inline-block;
            margin: auto;
            padding: 7px;
            font-size: 1.2rem;
            text-align: center;
            text-transform: uppercase;
            background: $color-white;
            border-radius: 4px;
          }
  
          .placesAvailables {
            margin-top: 0.5*$base-spacing;
            text-align: center;
          }
  
          .date {
            margin-top: 2*$base-spacing;
            text-align: center;
            font-size: 2.8rem;
          }
  
          .time {
            margin-top: 5px;
            text-align: center;
            font-size: 2.2rem;
          }
  
          .namely {
            margin-top: 2*$base-spacing;
            margin-bottom: 2*$base-spacing;
            text-align: center;
          }

          .modal-actions {
            margin-top: 5*$base-spacing;
            margin-bottom: 2*$base-spacing;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .cgenial-modal.partner {
    position: absolute;
    top: 0;
    left: 0;
  }
  
}


.fancybox-close-small svg {display:  none;}

.fancybox-close-small:after{
  content:"×";
  position:absolute;
  top:5px;right:5px;width:30px;height:30px;
  font:20px/30px Arial,Helvetica Neue,Helvetica,sans-serif;color:#888;font-weight:300;
  text-align:center;
  border-radius:50%;border-width:0;
  background-color:transparent;
  -webkit-transition:background-color .25s;transition:background-color .25s;
  -webkit-box-sizing:border-box;box-sizing:border-box;
  z-index:2
}
.fancybox-close-small:focus{outline:0}
.fancybox-close-small:focus:after{outline:1px dotted #888}
.fancybox-close-small:hover:after{color:#555;background:#eee}
.fancybox-slide--iframe .fancybox-close-small,.fancybox-slide--image .fancybox-close-small{top:0;right:-44px}
.fancybox-slide--iframe .fancybox-close-small:after,.fancybox-slide--image .fancybox-close-small:after{font-size:35px;color:#aaa}
.fancybox-slide--iframe .fancybox-close-small:hover:after,.fancybox-slide--image .fancybox-close-small:hover:after{color:#fff;background:0 0}