// Override some jQuery UI component properties

// Accordion
.ui-accordion {
  .ui-accordion-header,
  .ui-accordion-content {
    background: $color-background-secondary;
    border-color: $color-secondary;
    color: inherit;
  }

  .ui-accordion-header {
    font-weight: 700;
    padding: 1rem calc(2 * .5em + 1.6rem) 1rem 1rem;
    position: relative;

    &:not(:first-child) {
      margin-top: .5em;
    }

    // Default jQuery UI icon
    .ui-accordion-header-icon {
      display: none;
    }

    // Custom icon
    .icon {
      float: right;
      margin-top: -.8rem;
      position: absolute;
      right: .5em; // .ui-accordion-header padding-right
      top: 50%;
      transform: rotate(180deg);
    }

    &.ui-state-focus {
      .icon {
        transition: transform 400ms ease; // This duration is the value set for the jQuery UI Accordion effects duration
      }
    }

    &.ui-state-active {
      .icon {
        transform: rotate(90deg);
      }
    }
  }

  .ui-accordion-content {
    padding: 0 .5em;
  }
}

.ui-corner-all,
.ui-corner-top,
.ui-corner-right,
.ui-corner-tr {
  border-top-right-radius: 1rem;
}

.ui-corner-all,
.ui-corner-top,
.ui-corner-left,
.ui-corner-tl {
  border-top-left-radius: 1rem;
}

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
  border-bottom-right-radius: 1rem;
}

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-left,
.ui-corner-bl {
  border-bottom-left-radius: 1rem;
}
