.website-header {
    .website-brand {
        display: block;
        float: left;
        max-width: 200px;

        svg, img {
            max-width: 100% !important;
            max-height: 150px;
            width: 100%;
        }
    }

    .website-baseline, .website-menu-connect {
        display: block;
        margin-top: -2*$base-spacing;

        @include media-breakpoint-up(lg) {
            margin-top: 4*$base-spacing;
        }
    }

    .website-baseline {
        font: 400 1.8rem 'Oswald', 'Arial', sans-serif;
        float: left;
        margin-left: $base-spacing;
        margin-bottom: 2*$base-spacing;

        @include media-breakpoint-up(lg) {
            font-size: 2.4rem;
            margin-left: 2*$base-spacing;
            margin-bottom: 0;
        }
    }

    .website-menu-connect {
        text-align: right;

        .btn {
            + .btn {
                margin-left: 1*$base-spacing;    
            }
        }
    }
}

.website-header-nav-mobile {
    position: relative;
    padding: $base-spacing 0;
    background: $color-concrete;

    .website-menu-connect {
        margin-top: 0.5*$base-spacing;
        text-align: right;

        .btn {
            + .btn {
                margin-left: 1*$base-spacing;    
            }
        }    
    }
}