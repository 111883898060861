.mosaic {
    overflow: hidden;
    
    .row {
        margin: 0;
    }
    div[class^="col-"], div[class*=" col-"] {
        padding: 0;
    }

    .row .row {
        @media (min-width:1200px) {
            height: 50%; 
        }   
    }

    .module {
        display: block;
        width: 100%;
        height: 100%;

        @include media-breakpoint-down(lg) {
            min-height: 200px;
            display: flex;
            align-items: center;
        }

        &.pa {
            padding: 90px 30px;
        }

        .item-content {
            display: block;
            width: 100%;
            height: 100%;    
        }

        h2, .h2 {
            font-size: 4.7rem;
            line-height: 1.5em;
        }
        
        p {
            display: table;
            width: 100%;
            height: 100%;
            margin: 0;
        }

        p.img {
            display: block;
            width: auto;
            height: auto;
            margin: 0;

            img {
                max-width: 180px;
            }
        }

        a:not(.btn) {
            display: table-cell;
            width: 100%;
            height: 100%;
            padding: 30px;
            vertical-align: bottom;

            cursor: pointer;
            
            font-family: 'Oswald', 'Arial', sans-serif;
            font-size: 3.8rem;
            line-height: 1.1em;
            font-weight: 400;
            text-transform: uppercase;

            color: $color-white !important;

            &:hover, &:focus {
                text-decoration: none;
                color: $color-white !important;
                background: $color-tundora;
                background: rgba(73,73,73, 0.5);
            }
        }

        &.bg-tundora {
            a:not(.btn) {
                &:hover, &:focus {
                    background: $color-alabaster;
                    background: rgba(182,182,182, 0.5);
                }
            }    
        }
    }

    .second-col .second-line .module {
        a:not(.btn) {
            padding-left: 15px;
            padding-right: 15px;

            font-size: 3rem;
        }
    }
}