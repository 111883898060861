body {
    &.presentation {
        .maincontent {
            .after_body {
                .module {
                    padding-bottom: 5*$base-spacing; 

                    &:nth-child(odd):not(.not-separator) {
                        @extend .bg-alabaster;
                        @extend .bg-alabaster.tseparator;
                    }
                    &:nth-child(even):not(.not-separator) {
                        @extend .bg-white;
                        @extend .bg-white.tseparator;   
                    }
                    &:last-child {
                        padding-bottom: 5*$base-spacing;
                    }
                }
            }
        }
    }
}