#collection-merITDLC .form-group > legend, 
#collection-merITDLC .form-group > label {
    flex: 0 0 20%;
}

#collection-merITDLC .form-group > div {
    flex: 0 0 30%;
}


#collection-merITDLC .form-group:nth-child(1n) {
    width: 100%;
}


#collection-merITDLC .form-group:nth-child(2n) .select-wrapper,
 #collection-merITDLC .form-group:nth-child(3n) .select-wrapper, 
 #collection-merITDLC .form-group:nth-child(5n) .select-wrapper {
    width: 80px;
}

#collection-merITDLC .form-group:nth-child(3n) {
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 1em;
    margin-bottom: 1em;
}

#collection-merITDLC .form-collection--add {
    display: none;
}