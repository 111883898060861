// Main menu
.website-menu-main {
  position: relative;
  background: $color-concrete;
  .main-menu {
    @extend .row;
    @extend .justify-content-center;
    position: relative;
    margin: 0;
    padding: 0;
    text-align: center;
    > .menu-item {
      @extend .col-lg;
      position: static;
      padding: 0;
      &.menu-item-has-children {}
      span {
        cursor: default;
      }
      >.menu-link {
        position: relative;
        display: block;
        padding: 1*$base-spacing 1*$base-spacing;
        font-family: 'Oswald', 'Arial', sans-serif;
        font-size: 2.7rem;
        line-height: 1.1em;
        font-weight: 400;
        text-transform: uppercase;
        text-decoration: none;
        color: $color-silver-chalice;
      }
      .container-sub-menu {
        @include media-breakpoint-up(lg) {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 100;
        display: none;
        padding: 3*$base-spacing 1*$base-spacing;
        text-align: left;
        background: $color-mine-shaft;
        // background: $color-tundora;
        // border-bottom: 2px solid $color-white;
        }
        width: 100%;
        padding: 0 1*$base-spacing 2*$base-spacing;
        color: $color-silver-chalice;
  
        .sub-menu {
          @extend .row;
          position: relative;
          margin: 0;
          padding: 0;
          .menu-item {
            @extend .col-lg-4;
            position: static;
            padding: 0;
            &.menu-item-has-children {}
            .menu-link {
              display: inline-block;
              font-weight: normal;
              padding: 1*$base-spacing 1*$base-spacing;
              color: $color-silver-chalice;
              text-decoration: none;
              &:hover, &:focus {
                color: $color-tundora;

                @include media-breakpoint-up(lg) {
                  color: $color-white;
                } 
              }
            }

            &.entry-devenez-partenaire, &.entry--90-partenaires-92-devenez-partenaire, &.entry-soutenir, &.entry-nous-soutenir, &.entry-soutenez-nous {
              .menu-link {
                @extend .btn;
                @extend .btn-tangerine;

                margin-top: 3px;
                margin-left: 1*$base-spacing;
              }
            }
          }
        }

        .text-widget {
          clear: both;
          padding: 1*$base-spacing 1*$base-spacing 0;

          p {
            position: relative;
            padding-left: 2.2*$base-spacing;
            font-weight: bold;
            font-size: $font-size-small;
            line-height: 2em;
            color: $color-white;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 33px;
                height: 25px;
                background: url(../images/__cgenial/quote.png) no-repeat center center;
                background-size: 50%;
            }
          }
        }
      }
      &:hover, &:focus {
        >.menu-link {
          color: $color-tundora;
        }
        &.menu-item-has-children {
          >.menu-link {
            &::after {
              position: absolute;
              left: 50%;
              bottom: -20px;
              margin-left: -25px;

              content: '';
              width: 0; 
              height: 0; 
              border-left: 20px solid transparent;
              border-right: 20px solid transparent;
              border-top: 20px solid $color-concrete;

              z-index: 101;
            }
          }
        }
        .container-sub-menu {
          display: block;
        }
      }
    }
    .active {
      >.menu-link {
        color: $color-tundora; 
      }
      .container-sub-menu {
        .sub-menu {
          .menu-item.active {
            .menu-link {
              color: $color-tundora;

              @include media-breakpoint-up(lg) {
                color: $color-white;
              }
            }
          }
        }
      }
    }
  }
}

#footer {
  .footer-menu {
    > li {
      margin-bottom: 2*$base-spacing;

      > a, > span {
        display: block;
        color: $color-white !important; 
        font-family: 'Oswald', 'Arial', sans-serif;
        font-size: $font-size-h4;
        line-height: $line-height-h4;
        font-weight: 400;
        text-decoration: none;
        text-transform: uppercase;
        
      }

      > a:hover, > a:focus {
        color: $color-tangerine !important; 
      }

      ul {
        margin-top: 2*$base-spacing;

        li {
          a, span {
            display: block;
            padding-bottom: 1*$base-spacing;
            color: $color-silver-chalice !important;
            text-decoration: none;
            &:hover, &:focus {
              color: $color-white !important; 
            }
          }
        }
      }
    }

    // @include media-breakpoint-up(md) {
    //   > li.entry-la-fondation, > li.entry-nos-actions, > li.entry-actualites {
    //     margin-top: -221px; // -105px + img height
    //   }
    // }

    @include media-breakpoint-down(md) {
      > li.entry-la-fondation {
        order: 1;
      }
      
      > li.entry-nos-actions {
        order: 2;
      } 

      > li.entry-actualites {
        order: 3;
      } 

      > li.entry-partenaires {
        order: 4;
      } 

      > li.entry--81-contact {
        order: 5;
      } 

      > li.entry-mentions-legales {
        order: 6;
      } 

      > li.entry-credits {
        order: 7;
      } 
    }
  }
}

.nav.nav-tabs {
  //@extend .row;
  //@extend .justify-content-center;
  border: none;

  @include media-breakpoint-down(sm) {
    display: block;     
  }

  .nav-item {
    //@extend .col-md;
    margin: 0;
    padding: 0 7.5px;
    max-width: 360px;

    @include media-breakpoint-up(lg) {
        padding: 0 7.5px;
    }

    @include media-breakpoint-down(sm) {
      display: block; 
      margin: $base-spacing auto;    
    }
  }

  .nav-link {
    padding: 1.66*$base-spacing 1*$base-spacing;
    border: none;
    color: $color-tundora !important;
    background: $color-mercury;

    font-family: 'Oswald', 'Arial', sans-serif;
    font-size: 1.8rem;
    line-height: 1.1;
    text-align: center;
    text-transform: uppercase;

    @include border-top-radius(2px);

    display: table;
    width: 100%;
    height: 100%; 
    
    span {
      display: table-cell;
      vertical-align: middle;
    }

    &.disabled {
      color: $color-silver-chalice !important;
      background: $color-alabaster !important;
      cursor: default;
    }

    &:not(.disabled):hover, &:not(.disabled):focus, &.active {
      color: $color-tundora !important;
      background: $color-white;
    }
  }

  &.align-top {
    .nav-link {
      span {
        vertical-align: top;
      }
    }
  }

  &.align-bottom {
    .nav-link {
      span {
        vertical-align: bottom;
      }
    }
  }
}

.nav.nav-pills {

  &:not(.no-margin-bottom) {
    margin-bottom: 2*$base-spacing;
  }

  .nav-link {
    padding: 1*$base-spacing 2*$base-spacing;  
  }

  .nav-link.active,
  .show > .nav-link {
    @extend .bg-theme;
  }
}

.nav.nav-counter {
  counter-reset: item;
  
  .nav-link {
    counter-increment: item;

    &::before {
      content: counter(item) "."; 
      display: inline-block; 
      width: 1em; 
      margin-left: -3.5rem;
      margin-right: 0.6rem; 
      text-align: right; 
      //direction: rtl;
    }
  }
}

.pagination {
  display: block;
  width: 100%;
  margin: 2*$base-spacing auto;
  text-align: center; // If .pagination is a div
  ul {
    display: block;
    width: 100%;
    text-align: center;
  }
  li {
    display: inline-block;
    width: 30px;
    height: 30px;
    padding-left: 0;
    margin-right: 1*$base-spacing;
    @media (min-width: 1200px) and (max-width:1439px) {
      margin-right: 0.333*$base-spacing;
    }

    &::before {
      content: none !important;
    }
  }
}