.bg {
    &-silver-chalice {
        background-color: $color-silver-chalice;
    }
    &-concrete {
        background-color: $color-concrete;
    }
    &-mercury {
        background-color: $color-mercury;
    }
    &-alabaster {
        background-color: $color-alabaster;
    }
    &-la-rioja {
        background-color: $color-la-rioja;
    }
    &-pelorous {
        background-color: $color-pelorous;
    }
    &-tangerine,
    &-theme {
        background-color: $color-tangerine;
    }
    &-tundora {
        background-color: $color-tundora;
    }
    &-froly {
        background-color: $color-froly;
    }
    &-white {
        background-color: $color-white;
    }
    &-connect {
        background-color: $color-connect;
    }
    /*
    * Dependencies
    */
    &-la-rioja,
    &-pelorous,
    &-tangerine,
    &-theme,
    &-froly,
    &-tundora {
        color: $color-white;
        h1,
        .h1,
        h2,
        .h2,
        h3,
        .h3,
        h4,
        .h4,
        h5,
        .h5,
        h6,
        .h6,
        p,
        .p {
            color: $color-white;
        }
    }
    &-la-rioja,
    &-pelorous,
    &-tangerine,
    &-theme {
        a:not(.btn):not(.nav-link) {
            color: $color-white !important;
            &:hover,
            &:focus {
                color: $color-tundora !important;
            }
        }
    }
    &-tundora {
        a:not(.btn):not(.nav-link) {
            color: $color-white !important;
            &:hover,
            &:focus {
                color: $color-tangerine !important;
            }
        }
        .btn.btn-tundora {
            &:hover,
            &:focus {
                color: $color-white !important;
                background: $color-tangerine !important;
            }
        }
    }
    &-la-rioja {
        body.la-rioja & .btn.btn-theme {
            background: $color-tundora !important;
        }
        .btn.btn-tangerine,
        .btn.btn-pelorous {
            &:hover,
            &:focus {
                background: $color-tundora !important;
            }
        }
    }
    &-pelorous {
        body.pelorous & .btn.btn-theme {
            background: $color-tundora !important;
        }
        .btn.btn-la-rioja,
        .btn.btn-tangerine {
            &:hover,
            &:focus {
                background: $color-tundora !important;
            }
        }
        .btn.btn-tundora {
            &:hover,
            &:focus {
                background: $color-dusty-gray !important;
            }
        }
    }
    &-tangerine {
        body.tangerine & .btn.btn-theme {
            background: $color-tundora !important;
        }
        .btn.btn-la-rioja,
        .btn.btn-pelorous {
            &:hover,
            &:focus {
                background: $color-tundora !important;
            }
        }
        .btn.btn-tundora {
            &:hover,
            &:focus {
                background: $color-dusty-gray !important;
            }
        }
    }
    &-froly {
        body.froly & .btn.btn-theme {
            background: $color-tundora !important;
        }
        .btn.btn-la-rioja,
        .btn.btn-pelorous {
            &:hover,
            &:focus {
                background: $color-tundora !important;
            }
        }
        .btn.btn-tundora {
            &:hover,
            &:focus {
                background: $color-dusty-gray !important;
            }
        }
    }
    &-tundora {
        body.tundora & .btn.btn-theme {
            background: $color-tangerine !important;
            &:hover,
            &:focus {
                background: $color-dusty-gray !important;
            }
        }
        .btn {
            &:hover,
            &:focus {
                background: $color-dusty-gray !important;
            }
        }
    }
    /*
    * Separators
    */
    &-alabaster {
        &.tseparator {
            padding-top: 255px;
            background-image: url(../images/__cgenial/separators/fromWhiteToGrey.png);
            background-repeat: repeat-x;
            background-position: center top;
        }
        &.bseparator {
            padding-bottom: 365px;
            background-image: url(../images/__cgenial/separators/fromGreyToWhite.png);
            background-repeat: repeat-x;
            background-position: center bottom;
        }
    }
    &-white {
        &.tseparator {
            padding-top: 340px;
            background-image: url(../images/__cgenial/separators/fromGreyToWhite.png);
            background-repeat: repeat-x;
            background-position: center top;
        }
        &.bseparator {
            padding-bottom: 310px;
            background-image: url(../images/__cgenial/separators/fromWhiteToGrey.png);
            background-repeat: repeat-x;
            background-position: center bottom;
        }
    }
}

.color {
    &-silver-chalice {
        color: $color-silver-chalice !important;
    }
    &-concrete {
        color: $color-concrete !important;
    }
    &-mercury {
        color: $color-mercury !important;
    }
    &-alabaster {
        color: $color-alabaster !important;
    }
    &-la-rioja {
        color: $color-la-rioja !important;
    }
    &-pelorous {
        color: $color-pelorous !important;
    }
    &-tangerine,
    &-theme {
        color: $color-tangerine !important;
    }
    &-tundora {
        color: $color-tundora !important;
    }
    &-white {
        color: $color-white !important;
    }
    &-connect {
        color: $color-connect !important;
    }
}

.highlighted {
    @extend .color-theme;
}

.btn {
    color: $color-tundora !important;
    background-color: $color-concrete !important;
    &-silver-chalice {
        color: $color-tundora !important;
        background-color: $color-silver-chalice !important;
    }
    &-concrete {
        color: $color-tundora !important;
        background-color: $color-concrete !important;
    }
    &-mercury {
        color: $color-tundora !important;
        background-color: $color-mercury !important;
    }
    &-alabaster {
        background-color: $color-alabaster !important;
    }
    &-la-rioja {
        color: $color-white !important;
        background-color: $color-la-rioja !important;
    }
    &-pelorous {
        color: $color-white !important;
        background-color: $color-pelorous !important;
    }
    &-tangerine,
    &-theme {
        color: $color-white !important;
        background-color: $color-tangerine !important;
    }
    &-tundora {
        color: $color-white !important;
        background-color: $color-tundora !important;
    }
    &-white {
        background-color: $color-white !important;
    }
    &.btn-connect {
        color: $color-white !important;
        background-color: $color-connect !important;
    }
    &:hover,
    &:focus {
        color: $color-white !important;
        background-color: $color-tundora !important;
        &.btn-silver-chalice,
        &.btn-concrete,
        &.btn-mercury,
        &.btn-alabaster,
        &.btn-white,
        &.btn-theme,
        &.btn-connect,
        &.btn-tangerine {
            color: $color-white !important;
            background-color: $color-tundora !important;
        }
        &.btn-la-rioja,
        &.btn-pelorous,
        &.btn-tundora {
            color: $color-white !important;
            background-color: $color-tundora !important;
        }
    }
}

.link {
    &-silver-chalice {
        color: $color-silver-chalice !important;
    }
    &-concrete {
        color: $color-concrete !important;
    }
    &-mercury {
        color: $color-mercury !important;
    }
    &-alabaster {
        color: $color-alabaster !important;
    }
    &-la-rioja {
        color: $color-la-rioja !important;
    }
    &-pelorous {
        color: $color-pelorous !important;
    }
    &-tangerine,
    &-theme {
        color: $color-tangerine !important;
    }
    &-tundora {
        color: $color-tundora !important;
    }
    &-froly {
        color: $color-froly !important;
    }
    &-white {
        color: $color-white !important;
    }
    &:hover,
    &:focus {
        color: $color-tangerine !important;
        &.link-silver-chalice,
        &.link-concrete,
        &.link-mercury,
        &.link-alabaster,
        &.link-white,
        &.link-theme,
        &.link-tangerine {
            color: $color-tundora !important;
        }
        &.link-la-rioja,
        &.link-pelorous,
        &.link-tundora {
            color: $color-tangerine !important;
        }
    }
    &-more,
    &-back {
        &-silver-chalice {
            @extend .link-silver-chalice;
            &>span {
                &:before {
                    color: $color-silver-chalice !important;
                }
            }
        }
        &-concrete {
            @extend .link-concrete;
            &>span {
                &:before {
                    color: $color-concrete !important;
                }
            }
        }
        &-mercury {
            @extend .link-mercury;
            &>span {
                &:before {
                    color: $color-mercury !important;
                }
            }
        }
        &-alabaster {
            @extend .link-alabaster;
            &>span {
                &:before {
                    color: $color-alabaster !important;
                }
            }
        }
        &-la-rioja {
            @extend .link-la-rioja;
            &>span {
                &:before {
                    color: $color-la-rioja !important;
                }
            }
        }
        &-pelorous {
            @extend .link-pelorous;
            &>span {
                &:before {
                    color: $color-pelorous !important;
                }
            }
        }
        &-tangerine,
        &-theme {
            @extend .link-tangerine;
            &>span {
                &:before {
                    color: $color-tangerine !important;
                }
            }
        }
        &-froly {
            @extend .link-froly;
            &>span {
                &:before {
                    color: $color-froly !important;
                }
            }
        }
        &-tundora {
            @extend .link-tundora;
            &>span {
                &:before {
                    color: $color-tundora !important;
                }
            }
        }
        &-white {
            color: $color-white !important;
            &>span {
                &:before {
                    color: $color-white !important;
                }
            }
        }
        &:hover,
        &:focus {
            color: $color-tundora !important;
            &>span {
                &:before {
                    color: $color-tundora !important;
                }
                i:before {
                    color: $color-white !important;
                }
            }
            &.link-more-silver-chalice,
            &.link-more-concrete,
            &.link-more-mercury,
            &.link-more-alabaster,
            &.link-more-white,
            &.link-more-theme,
            &.link-more-tangerine,
            &.link-more-froly,
            &.link-back-silver-chalice,
            &.link-back-concrete,
            &.link-back-mercury,
            &.link-back-alabaster,
            &.link-back-white,
            &.link-back-theme,
            &.link-back-tangerine,
            &.link-back-froly {
                color: $color-tundora !important;
                &>span {
                    &:before {
                        color: $color-tundora !important;
                    }
                    i:before {
                        color: $color-white !important;
                    }
                }
            }
            &.link-more-la-rioja,
            &.link-more-pelorous,
            &.link-more-tundora,
            &.link-back-la-rioja,
            &.link-back-pelorous,
            &.link-back-tundora {
                color: $color-tangerine !important;
                &>span {
                    &:before {
                        color: $color-tangerine !important;
                    }
                    i:before {
                        color: $color-white !important;
                    }
                }
            }
        }
    }
}

.bubble {
    color: $color-tundora !important;
    &>* {
        color: $color-tundora !important;
    }
    &::before {
        color: $color-alabaster !important;
    }
    &-silver-chalice {
        &::before {
            color: $color-silver-chalice !important;
        }
    }
    &-concrete {
        &::before {
            color: $color-concrete !important;
        }
    }
    &-mercury {
        &::before {
            color: $color-mercury !important;
        }
    }
    &-alabaster {
        &::before {
            color: $color-alabaster !important;
        }
    }
    &-la-rioja {
        color: $color-white !important;
        &>* {
            color: $color-white !important;
        }
        &::before {
            color: $color-la-rioja !important;
        }
    }
    &-pelorous {
        color: $color-white !important;
        &>* {
            color: $color-white !important;
        }
        &::before {
            color: $color-pelorous !important;
        }
    }
    &-tangerine,
    &-theme {
        color: $color-white !important;
        &>* {
            color: $color-white !important;
        }
        &::before {
            color: $color-tangerine !important;
        }
    }
    &-froly {
        color: $color-white !important;
        &>* {
            color: $color-white !important;
        }
        &::before {
            color: $color-pelorous !important;
        }
    }
    &-tundora {
        color: $color-white !important;
        &>* {
            color: $color-white !important;
        }
        &::before {
            color: $color-tundora !important;
        }
    }
    &-white {
        &::before {
            color: $color-white !important;
        }
    }
}

a.bubble {
    &:hover,
    &:focus {
        color: $color-white !important;
        &>* {
            color: $color-white !important;
        }
        &::before {
            color: $color-tangerine !important;
        }
        &.bubble-silver-chalice,
        &.bubble-concrete,
        &.bubble-mercury,
        &.bubble-alabaster,
        &.bubble-white,
        &.bubble-theme,
        &.bubble-tangerine {
            color: $color-white !important;
            &>* {
                color: $color-white !important;
            }
            &::before {
                color: $color-tangerine !important;
            }
        }
        &.bubble-la-rioja,
        &.bubble-pelorous,
        &.bubble-froly,
        &.bubble-tundora {
            color: $color-white !important;
            &>* {
                color: $color-white !important;
            }
            &::before {
                color: $color-tangerine !important;
            }
        }
    }
}

.page-header {
    &.has-bg-image {
        &.bg-silver-chalice {
            &::before {
                background: $color-silver-chalice;
                opacity: 0.85;
                @media (min-width: 992px) {
                    background: linear-gradient(to right, $color-silver-chalice 50%, transparent);
                    opacity: 1;
                }
            }
        }
        &.bg-concrete {
            &::before {
                background: $color-concrete;
                opacity: 0.85;
                @media (min-width: 992px) {
                    background: linear-gradient(to right, $color-concrete 50%, transparent);
                    opacity: 1;
                }
            }
        }
        &.bg-mercury {
            &::before {
                background: $color-mercury;
                opacity: 0.85;
                @media (min-width: 992px) {
                    background: linear-gradient(to right, $color-mercury 50%, transparent);
                    opacity: 1;
                }
            }
        }
        &.bg-alabaster {
            &::before {
                background: $color-alabaster;
                opacity: 0.85;
                @media (min-width: 992px) {
                    background: linear-gradient(to right, $color-alabaster 50%, transparent);
                    opacity: 1;
                }
            }
        }
        &.bg-la-rioja {
            &::before {
                background: $color-la-rioja;
                opacity: 0.85;
                @media (min-width: 992px) {
                    background: linear-gradient(to right, $color-la-rioja 50%, transparent);
                    opacity: 1;
                }
            }
        }
        &.bg-pelorous {
            &::before {
                background: $color-pelorous;
                opacity: 0.85;
                @media (min-width: 992px) {
                    background: linear-gradient(to right, $color-pelorous 50%, transparent);
                    opacity: 1;
                }
            }
        }
        &.bg-tangerine,
        &.bg-theme {
            &::before {
                background: $color-tangerine;
                opacity: 0.85;
                @media (min-width: 992px) {
                    background: linear-gradient(to right, $color-tangerine 50%, transparent);
                    opacity: 1;
                }
            }
        }
        &.bg-froly {
            &::before {
                background: $color-froly;
                opacity: 0.85;
                @media (min-width: 992px) {
                    background: linear-gradient(to right, $color-froly 50%, transparent);
                    opacity: 1;
                }
            }
        }
        &.bg-tundora {
            &::before {
                background: $color-tundora;
                opacity: 0.85;
                @media (min-width: 992px) {
                    background: linear-gradient(to right, $color-tundora 50%, transparent);
                    opacity: 1;
                }
            }
        }
        &.bg-white {
            &::before {
                background: $color-white;
                opacity: 0.85;
                @media (min-width: 992px) {
                    background: linear-gradient(to right, $color-white 50%, transparent);
                    opacity: 1;
                }
            }
        }
    }
}

.form-check {
    input[type="checkbox"] {
        &.form-check-color-la-rioja:checked {
            +label {
                &::after {
                    color: $color-la-rioja;
                }
            }
        }
        &.form-check-color-pelorous:checked {
            +label {
                &::after {
                    color: $color-pelorous;
                }
            }
        }
        &.form-check-color-tangerine:checked {
            +label {
                &::after {
                    color: $color-tangerine;
                }
            }
        }
        &.form-check-color-froly:checked {
            +label {
                &::after {
                    color: $color-froly;
                }
            }
        }
        &.form-check-color-tundora:checked {
            +label {
                &::after {
                    color: $color-tundora;
                }
            }
        }
    }
    input[type="radio"] {
        &.form-check-color-la-rioja+label {
            &::after {
                background-color: $color-la-rioja;
            }
        }
        &.form-check-color-pelorous+label {
            &::after {
                background-color: $color-pelorous;
            }
        }
        &.form-check-color-tangerine+label {
            &::after {
                background-color: $color-tangerine;
            }
        }
        &.form-check-color-froly+label {
            &::after {
                background-color: $color-froly;
            }
        }
        &.form-check-color-tundora+label {
            &::after {
                background-color: $color-tundora;
            }
        }
    }
}

body {
    &.la-rioja {
        h1,
        .h1,
        h2,
        .h2,
        h3,
        .h3,
        h4,
        .h4,
        h5,
        .h5,
        h6,
        .h6 {
            color: $color-la-rioja;
        }
        a {
            color: $color-la-rioja;
            &:hover,
            &:focus {
                color: $color-tangerine;
            }
        }
        .body,
        .before_body,
        .after_body {
            ul:not(.no-style),
            ol:not(.no-style) {
                li::before {
                    color: $color-la-rioja;
                }
            }
        }
        .page-header {
            &.has-bg-image {
                &.bg-theme {
                    &::before {
                        background: $color-la-rioja;
                        opacity: 0.85;
                        @media (min-width: 992px) {
                            background: linear-gradient(to right, $color-la-rioja 50%, transparent);
                            opacity: 1;
                        }
                    }
                }
            }
            h1 {
                color: $color-white;
            }
        }
        .bg {
            &-theme {
                background-color: $color-la-rioja;
            }
            /*
            * Dependencies
            */
            &-la-rioja,
            &-pelorous,
            &-tangerine,
            &-theme,
            &-tundora {
                color: $color-white;
                h1,
                .h1,
                h2,
                .h2,
                h3,
                .h3,
                h4,
                .h4,
                h5,
                .h5,
                h6,
                .h6,
                p,
                .p {
                    color: $color-white;
                }
            }
            &-la-rioja,
            &-pelorous,
            &-tangerine,
            &-froly,
            &-theme {
                a:not(.btn):not(.nav-link) {
                    color: $color-white !important;
                    &:hover,
                    &:focus {
                        color: $color-tundora !important;
                    }
                }
            }
            &-tundora {
                a:not(.btn):not(.nav-link) {
                    color: $color-white !important;
                    &:hover,
                    &:focus {
                        color: $color-tangerine !important;
                    }
                }
            }
        }
        .color {
            &-theme {
                color: $color-la-rioja !important;
            }
        }
        .highlighted {
            color: $color-la-rioja !important;
        }
        .pagination {
            a.bubble {
                color: $color-la-rioja !important;
                &>* {
                    color: $color-la-rioja !important;
                }
            }
        }
        .bubble {
            &-theme {
                color: $color-white !important;
                &>* {
                    color: $color-white !important;
                }
                &::before {
                    color: $color-la-rioja !important;
                }
            }
        }
        a.bubble {
            &:hover,
            &:focus {
                color: $color-white !important;
                &>* {
                    color: $color-white !important;
                }
                &::before {
                    color: $color-la-rioja !important;
                }
                &.bubble-theme {
                    color: $color-white !important;
                    &>* {
                        color: $color-white !important;
                    }
                    &::before {
                        color: $color-tundora !important;
                    }
                }
            }
        }
        .btn {
            &-theme {
                color: $color-white !important;
                background-color: $color-la-rioja !important;
            }
            &:hover,
            &:focus {
                &.btn-la-rioja,
                &.btn-theme {
                    color: $color-white !important;
                    background-color: $color-tundora !important;
                }
                &.btn-tangerine,
                &.btn-pelorous,
                &.btn-tundora {
                    color: $color-white !important;
                    background-color: $color-la-rioja !important;
                }
            }
        }
        .link {
            &-theme {
                color: $color-la-rioja !important;
            }
            &:hover,
            &:focus {
                &.link-la-rioja,
                &.link-theme {
                    color: $color-tundora !important;
                }
                &.link-tangerine,
                &.link-pelorous,
                &.link-tundora {
                    color: $color-la-rioja !important;
                }
            }
            &-more,
            &-back {
                &-theme {
                    color: $color-la-rioja !important;
                    &>span {
                        &:before {
                            color: $color-la-rioja !important;
                        }
                        i:before {
                            color: $color-white !important;
                        }
                    }
                }
                &:hover,
                &:focus {
                    &.link-more-la-rioja,
                    &.link-more-theme,
                    &.link-back-la-rioja,
                    &.link-back-theme {
                        color: $color-tundora !important;
                        &>span {
                            &:before {
                                color: $color-tundora !important;
                            }
                            i:before {
                                color: $color-white !important;
                            }
                        }
                    }
                    &.link-more-tangerine,
                    &.link-more-pelorous,
                    &.link-more-tundora,
                    &.link-back-tangerine,
                    &.link-back-pelorous,
                    &.link-back-tundora {
                        color: $color-la-rioja !important;
                        &>span {
                            &:before {
                                color: $color-la-rioja !important;
                            }
                            i:before {
                                color: $color-white !important;
                            }
                        }
                    }
                }
            }
        }
        .form-check {
            input[type="checkbox"] {
                &:checked {
                    +label {
                        &::after {
                            color: $color-la-rioja;
                        }
                    }
                }
            }
            input[type="radio"] {
                +label {
                    &::after {
                        background-color: $color-la-rioja;
                    }
                }
            }
        }
    }
    &.pelorous {
        h1,
        .h1,
        h2,
        .h2,
        h3,
        .h3,
        h4,
        .h4,
        h5,
        .h5,
        h6,
        .h6 {
            color: $color-pelorous;
        }
        a {
            color: $color-pelorous;
            &:hover,
            &:focus {
                color: $color-tangerine;
            }
        }
        .body,
        .before_body,
        .after_body {
            ul:not(.no-style),
            ol:not(.no-style) {
                li::before {
                    color: $color-pelorous;
                }
            }
        }
        .page-header {
            &.has-bg-image {
                &.bg-theme {
                    &::before {
                        background: $color-pelorous;
                        opacity: 0.85;
                        @media (min-width: 992px) {
                            background: linear-gradient(to right, $color-pelorous 50%, transparent);
                            opacity: 1;
                        }
                    }
                }
            }
            h1 {
                color: $color-white;
            }
        }
        .bg {
            &-theme {
                background-color: $color-pelorous;
            }
            /*
            * Dependencies
            */
            &-la-rioja,
            &-pelorous,
            &-tangerine,
            &-theme,
            &-tundora {
                color: $color-white;
                h1,
                .h1,
                h2,
                .h2,
                h3,
                .h3,
                h4,
                .h4,
                h5,
                .h5,
                h6,
                .h6,
                p,
                .p {
                    color: $color-white;
                }
            }
            &-la-rioja,
            &-pelorous,
            &-tangerine,
            &-froly,
            &-theme {
                a:not(.btn):not(.nav-link) {
                    color: $color-white !important;
                    &:hover,
                    &:focus {
                        color: $color-tundora !important;
                    }
                }
            }
            &-tundora {
                a:not(.btn):not(.nav-link) {
                    color: $color-white !important;
                    &:hover,
                    &:focus {
                        color: $color-tangerine !important;
                    }
                }
            }
        }
        .color {
            &-theme {
                color: $color-pelorous !important;
            }
        }
        .highlighted {
            color: $color-pelorous !important;
        }
        .pagination {
            a.bubble {
                color: $color-pelorous !important;
                &>* {
                    color: $color-pelorous !important;
                }
            }
        }
        .bubble {
            &-theme {
                color: $color-white !important;
                &>* {
                    color: $color-white !important;
                }
                &::before {
                    color: $color-pelorous !important;
                }
            }
        }
        a.bubble {
            &:hover,
            &:focus {
                color: $color-white !important;
                &>* {
                    color: $color-white !important;
                }
                &::before {
                    color: $color-pelorous !important;
                }
                &.bubble-theme {
                    color: $color-white !important;
                    &>* {
                        color: $color-white !important;
                    }
                    &::before {
                        color: $color-tundora !important;
                    }
                }
            }
        }
        .btn {
            &-theme {
                color: $color-white !important;
                background-color: $color-pelorous !important;
            }
            &:hover,
            &:focus {
                &.btn-pelorous,
                &.btn-theme {
                    color: $color-white !important;
                    background-color: $color-tundora !important;
                }
                &.btn-la-rioja,
                &.btn-tangerine,
                &.btn-tundora {
                    color: $color-white !important;
                    background-color: $color-pelorous !important;
                }
            }
        }
        .link {
            &-theme {
                color: $color-pelorous !important;
            }
            &:hover,
            &:focus {
                &.link-pelorous,
                &.link-theme {
                    color: $color-tundora !important;
                }
                &.link-la-rioja,
                &.link-tangerine,
                &.link-tundora {
                    color: $color-pelorous !important;
                }
            }
            &-more,
            &-back {
                &-theme {
                    color: $color-pelorous !important;
                    &>span {
                        &:before {
                            color: $color-pelorous !important;
                        }
                        i:before {
                            color: $color-white !important;
                        }
                    }
                }
                &:hover,
                &:focus {
                    &.link-more-pelorous,
                    &.link-more-theme,
                    &.link-back-pelorous,
                    &.link-back-theme {
                        color: $color-tundora !important;
                        &>span {
                            &:before {
                                color: $color-tundora !important;
                            }
                            i:before {
                                color: $color-white !important;
                            }
                        }
                    }
                    &.link-more-la-rioja,
                    &.link-more-tangerine,
                    &.link-more-tundora,
                    &.link-back-la-rioja,
                    &.link-back-tangerine,
                    &.link-back-tundora {
                        color: $color-pelorous !important;
                        &>span {
                            &:before {
                                color: $color-pelorous !important;
                            }
                            i:before {
                                color: $color-white !important;
                            }
                        }
                    }
                }
            }
        }
        .form-check {
            input[type="checkbox"] {
                &:checked {
                    +label {
                        &::after {
                            color: $color-pelorous;
                        }
                    }
                }
            }
            input[type="radio"] {
                +label {
                    &::after {
                        background-color: $color-pelorous;
                    }
                }
            }
        }
    }
    &.tangerine {
        h1,
        .h1,
        h2,
        .h2,
        h3,
        .h3,
        h4,
        .h4,
        h5,
        .h5,
        h6,
        .h6 {
            color: $color-tangerine;
        }
        a {
            color: $color-tangerine;
            &:hover,
            &:focus {
                color: $color-tangerine;
            }
        }
        .body,
        .before_body,
        .after_body {
            ul:not(.no-style),
            ol:not(.no-style) {
                li::before {
                    color: $color-tangerine;
                }
            }
        }
        .page-header {
            &.has-bg-image {
                &.bg-theme {
                    &::before {
                        background: $color-tangerine;
                        opacity: 0.85;
                        @media (min-width: 992px) {
                            background: linear-gradient(to right, $color-tangerine 50%, transparent);
                            opacity: 1;
                        }
                    }
                }
            }
            h1 {
                color: $color-white;
            }
        }
        .bg {
            &-theme {
                background-color: $color-tangerine;
            }
            /*
            * Dependencies
            */
            &-la-rioja,
            &-pelorous,
            &-tangerine,
            &-theme,
            &-tundora {
                color: $color-white;
                h1,
                .h1,
                h2,
                .h2,
                h3,
                .h3,
                h4,
                .h4,
                h5,
                .h5,
                h6,
                .h6,
                p,
                .p {
                    color: $color-white;
                }
            }
            &-la-rioja,
            &-pelorous,
            &-tangerine,
            &-theme {
                a:not(.btn):not(.nav-link) {
                    color: $color-white !important;
                    &:hover,
                    &:focus {
                        color: $color-tundora !important;
                    }
                }
            }
            &-tundora {
                a:not(.btn):not(.nav-link) {
                    color: $color-white !important;
                    &:hover,
                    &:focus {
                        color: $color-tangerine !important;
                    }
                }
            }
        }
        .color {
            &-theme {
                color: $color-tangerine !important;
            }
        }
        .highlighted {
            color: $color-tangerine !important;
        }
        .pagination {
            a.bubble {
                color: $color-tangerine !important;
                &>* {
                    color: $color-tangerine !important;
                }
            }
        }
        .bubble {
            &-theme {
                color: $color-white !important;
                &>* {
                    color: $color-white !important;
                }
                &::before {
                    color: $color-tangerine !important;
                }
            }
        }
        a.bubble {
            &:hover,
            &:focus {
                color: $color-white !important;
                &>* {
                    color: $color-white !important;
                }
                &::before {
                    color: $color-tangerine !important;
                }
                &.bubble-theme {
                    color: $color-white !important;
                    &>* {
                        color: $color-white !important;
                    }
                    &::before {
                        color: $color-tundora !important;
                    }
                }
            }
        }
        .btn {
            &-theme {
                color: $color-white !important;
                background-color: $color-tangerine !important;
            }
            &:hover,
            &:focus {
                &.btn-tangerine,
                &.btn-theme {
                    color: $color-white !important;
                    background-color: $color-tundora !important;
                }
                &.btn-la-rioja,
                &.btn-pelorous,
                &.btn-tundora {
                    color: $color-white !important;
                    background-color: $color-tangerine !important;
                }
            }
        }
        .link {
            &-theme {
                color: $color-tangerine !important;
            }
            &:hover,
            &:focus {
                &.link-tangerine,
                &.link-theme {
                    color: $color-tundora !important;
                }
                &.link-la-rioja,
                &.link-pelorous,
                &.link-tundora {
                    color: $color-tangerine !important;
                }
            }
            &-more,
            &-back {
                &-theme {
                    color: $color-tangerine !important;
                    &>span {
                        &:before {
                            color: $color-tangerine !important;
                        }
                        i:before {
                            color: $color-white !important;
                        }
                    }
                }
                &:hover,
                &:focus {
                    &.link-more-tangerine,
                    &.link-more-theme,
                    &.link-back-tangerine,
                    &.link-back-theme {
                        color: $color-tundora !important;
                        &>span {
                            &:before {
                                color: $color-tundora !important;
                            }
                            i:before {
                                color: $color-white !important;
                            }
                        }
                    }
                    &.link-more-la-rioja,
                    &.link-more-pelorous,
                    &.link-more-tundora,
                    &.link-back-la-rioja,
                    &.link-back-pelorous,
                    &.link-back-tundora {
                        color: $color-tangerine !important;
                        &>span {
                            &:before {
                                color: $color-tangerine !important;
                            }
                            i:before {
                                color: $color-white !important;
                            }
                        }
                    }
                }
            }
        }
        .form-check {
            input[type="checkbox"] {
                &:checked {
                    +label {
                        &::after {
                            color: $color-tangerine;
                        }
                    }
                }
            }
            input[type="radio"] {
                +label {
                    &::after {
                        background-color: $color-tangerine;
                    }
                }
            }
        }
    }
    &.froly {
        h1,
        .h1,
        h2,
        .h2,
        h3,
        .h3,
        h4,
        .h4,
        h5,
        .h5,
        h6,
        .h6 {
            color: $color-froly;
        }
        a {
            color: $color-froly;
            &:hover,
            &:focus {
                color: $color-froly;
            }
        }
        .body,
        .before_body,
        .after_body {
            ul:not(.no-style),
            ol:not(.no-style) {
                li::before {
                    color: $color-froly;
                }
            }
        }
        .page-header {
            &.has-bg-image {
                &.bg-theme {
                    &::before {
                        background: $color-froly;
                        opacity: 0.85;
                        @media (min-width: 992px) {
                            background: linear-gradient(to right, $color-froly 50%, transparent);
                            opacity: 1;
                        }
                    }
                }
            }
            h1 {
                color: $color-white;
            }
        }
        .bg {
            &-theme {
                background-color: $color-froly;
            }
            /*
            * Dependencies
            */
            &-la-rioja,
            &-pelorous,
            &-tangerine,
            &-theme,
            &-tundora {
                color: $color-white;
                h1,
                .h1,
                h2,
                .h2,
                h3,
                .h3,
                h4,
                .h4,
                h5,
                .h5,
                h6,
                .h6,
                p,
                .p {
                    color: $color-white;
                }
            }
            &-la-rioja,
            &-pelorous,
            &-tangerine,
            &-theme {
                a:not(.btn):not(.nav-link) {
                    color: $color-white !important;
                    &:hover,
                    &:focus {
                        color: $color-tundora !important;
                    }
                }
            }
            &-tundora {
                a:not(.btn):not(.nav-link) {
                    color: $color-white !important;
                    &:hover,
                    &:focus {
                        color: $color-froly !important;
                    }
                }
            }
        }
        .color {
            &-theme {
                color: $color-froly !important;
            }
        }
        .highlighted {
            color: $color-froly !important;
        }
        .pagination {
            a.bubble {
                color: $color-froly !important;
                &>* {
                    color: $color-froly !important;
                }
            }
        }
        .bubble {
            &-theme {
                color: $color-white !important;
                &>* {
                    color: $color-white !important;
                }
                &::before {
                    color: $color-froly !important;
                }
            }
        }
        a.bubble {
            &:hover,
            &:focus {
                color: $color-white !important;
                &>* {
                    color: $color-white !important;
                }
                &::before {
                    color: $color-froly !important;
                }
                &.bubble-theme {
                    color: $color-white !important;
                    &>* {
                        color: $color-white !important;
                    }
                    &::before {
                        color: $color-froly !important;
                    }
                }
            }
        }
        .btn {
            &-theme {
                color: $color-white !important;
                background-color: $color-froly !important;
            }
            &:hover,
            &:focus {
                &.btn-tangerine,
                &.btn-theme {
                    color: $color-white !important;
                    background-color: $color-tundora !important;
                }
                &.btn-la-rioja,
                &.btn-pelorous,
                &.btn-tundora {
                    color: $color-white !important;
                    background-color: $color-froly !important;
                }
            }
        }
        .link {
            &-theme {
                color: $color-froly !important;
            }
            &:hover,
            &:focus {
                &.link-tangerine,
                &.link-theme {
                    color: $color-tundora !important;
                }
                &.link-la-rioja,
                &.link-pelorous,
                &.link-tundora {
                    color: $color-froly !important;
                }
            }
            &-more,
            &-back {
                &-theme {
                    color: $color-froly !important;
                    &>span {
                        &:before {
                            color: $color-froly !important;
                        }
                        i:before {
                            color: $color-white !important;
                        }
                    }
                }
                &:hover,
                &:focus {
                    &.link-more-tangerine,
                    &.link-more-theme,
                    &.link-back-tangerine,
                    &.link-back-theme {
                        color: $color-tundora !important;
                        &>span {
                            &:before {
                                color: $color-tundora !important;
                            }
                            i:before {
                                color: $color-white !important;
                            }
                        }
                    }
                    &.link-more-la-rioja,
                    &.link-more-pelorous,
                    &.link-more-tundora,
                    &.link-back-la-rioja,
                    &.link-back-pelorous,
                    &.link-back-tundora {
                        color: $color-froly !important;
                        &>span {
                            &:before {
                                color: $color-froly !important;
                            }
                            i:before {
                                color: $color-white !important;
                            }
                        }
                    }
                }
            }
        }
        .form-check {
            input[type="checkbox"] {
                &:checked {
                    +label {
                        &::after {
                            color: $color-froly;
                        }
                    }
                }
            }
            input[type="radio"] {
                +label {
                    &::after {
                        background-color: $color-froly;
                    }
                }
            }
        }
    }
    &.tundora {
        h1,
        .h1,
        h2,
        .h2,
        h3,
        .h3,
        h4,
        .h4,
        h5,
        .h5,
        h6,
        .h6 {
            color: $color-tundora;
        }
        a {
            color: $color-tundora;
            &:hover,
            &:focus {
                color: $color-tundora;
            }
        }
        .body,
        .before_body,
        .after_body {
            ul:not(.no-style),
            ol:not(.no-style) {
                li::before {
                    color: $color-tundora;
                }
            }
        }
        .page-header {
            &.has-bg-image {
                &.bg-theme {
                    &::before {
                        background: $color-tundora;
                        opacity: 0.85;
                        @media (min-width: 992px) {
                            background: linear-gradient(to right, $color-tundora 50%, transparent);
                            opacity: 1;
                        }
                    }
                }
            }
            h1 {
                color: $color-white !important;
            }
        }
        .bg {
            &-theme {
                background-color: $color-tundora;
            }
            /*
            * Dependencies
            */
            &-la-rioja,
            &-pelorous,
            &-tangerine,
            &-theme,
            &-tundora {
                color: $color-white;
                h1,
                .h1,
                h2,
                .h2,
                h3,
                .h3,
                h4,
                .h4,
                h5,
                .h5,
                h6,
                .h6,
                p,
                .p {
                    color: $color-white !important;
                }
            }
            &-la-rioja,
            &-pelorous,
            &-tangerine,
            &-theme {
                a:not(.btn):not(.nav-link) {
                    color: $color-white !important;
                    &:hover,
                    &:focus {
                        color: $color-tundora !important;
                    }
                }
            }
            &-tundora {
                a:not(.btn):not(.nav-link) {
                    color: $color-white !important;
                    &:hover,
                    &:focus {
                        color: $color-tangerine !important;
                    }
                }
            }
        }
        .color {
            &-theme {
                color: $color-tundora !important;
            }
        }
        .highlighted {
            color: $color-tundora !important;
        }
        .pagination {
            a.bubble {
                color: $color-tundora !important;
                &>* {
                    color: $color-tundora !important;
                }
            }
        }
        .bubble {
            &-theme {
                color: $color-white !important;
                &>* {
                    color: $color-white !important;
                }
                &::before {
                    color: $color-tundora !important;
                }
            }
        }
        a.bubble {
            &:hover,
            &:focus {
                color: $color-white !important;
                &>* {
                    color: $color-white !important;
                }
                &::before {
                    color: $color-tundora !important;
                }
                &.bubble-theme {
                    color: $color-white !important;
                    &>* {
                        color: $color-white !important;
                    }
                    &::before {
                        color: $color-tundora !important;
                    }
                }
            }
        }
        .btn {
            &-theme {
                color: $color-white !important;
                background-color: $color-tundora !important;
            }
            &:hover,
            &:focus {
                &.btn-tundora,
                &.btn-theme {
                    color: $color-white !important;
                    background-color: $color-tangerine !important;
                }
                &.btn-la-rioja,
                &.btn-tangerine,
                &.btn-pelorous {
                    color: $color-white !important;
                    background-color: $color-tundora !important;
                }
            }
        }
        .link {
            &-theme {
                color: $color-tundora !important;
            }
            &:hover,
            &:focus {
                &.link-tundora,
                &.link-theme {
                    color: $color-tangerine !important;
                }
                &.link-la-rioja,
                &.link-tangerine,
                &.link-pelorous {
                    color: $color-tundora !important;
                }
            }
            &-more,
            &-back {
                &-theme {
                    color: $color-tundora !important;
                    &>span {
                        &:before {
                            color: $color-tundora !important;
                        }
                        i:before {
                            color: $color-white !important;
                        }
                    }
                }
                &:hover,
                &:focus {
                    &.link-more-tundora,
                    &.link-more-theme,
                    &.link-back-tundora,
                    &.link-back-theme {
                        color: $color-tangerine !important;
                        &>span {
                            &:before {
                                color: $color-tangerine !important;
                            }
                            i:before {
                                color: $color-white !important;
                            }
                        }
                    }
                    &.link-more-la-rioja,
                    &.link-more-tangerine,
                    &.link-more-pelorous,
                    &.link-back-la-rioja,
                    &.link-back-tangerine,
                    &.link-back-pelorous {
                        color: $color-tundora !important;
                        &>span {
                            &:before {
                                color: $color-tundora !important;
                            }
                            i:before {
                                color: $color-white !important;
                            }
                        }
                    }
                }
            }
        }
        .form-check {
            input[type="checkbox"] {
                &:checked {
                    +label {
                        &::after {
                            color: $color-tundora;
                        }
                    }
                }
            }
            input[type="radio"] {
                +label {
                    &::after {
                        background-color: $color-tundora;
                    }
                }
            }
        }
    }
}