button:not(.fancybox-close-small):active {
  //position: relative;
  //top: 1px;
}

fieldset:not(.form-group) {
  padding: 2*$base-spacing;

  .module.pa & {
    padding: 0;
  }

  > legend {
    display: block;
    float: left;
    font-size: $font-size-h3;
    line-height: $line-height-h3;
    font-weight: bold;
    color: $color-silver-chalice;
    text-transform: uppercase;
    margin-bottom: 1*$base-spacing;

    padding: 0;
    @include media-breakpoint-up(sm) {
      padding: 0 0 0 calc(33.33333% + 5px);
    }

    + * {
      clear: left;
    }
  }
}

//Datepicker 
#ui-datepicker-div {
  z-index: 99995 !important;
}

// Custom file type input
.fakefile {
  [type='file'] {
    display: none;

    ~ * {
      display: inline-block;
    }
  }

  &--value {
    margin-right: 1em;
    width: auto;
  }
}

.form-check {
  padding-left: 22px;
  margin-bottom: 1*$base-spacing; 

  .form-check-input {
    margin-top: 1px;
    margin-left: -22px;
  }
}

// Définition des formulaires
.form-control,
[type='email'],
[type='file'],
[type='number'],
[type='password'],
[type='text'],
select,
textarea {
  background: $color-white;
  border: 1px solid #aaa;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 15px 10px;
  font-size: 1.6rem;

  &.with-border, &:focus {
    border: 2px solid $color-mercury;
    box-shadow: none;
    outline: none;
  }

  &.is-invalid {
    border-color: $color-error !important;
  }
}

.form-group {
  label, legend.col-form-label {
    color: $color-dusty-gray;
    padding-top: 15px;
  }
  label.required, legend.col-form-label.required {
    &::after {
      content: "*";
      margin-left: 5px;
      //color: $color-error;
    }
  }
}

fieldset.form-group {
  legend.col-form-label {
    padding-top: 0;
  }  
}

.select-wrapper {
	position: relative;
  overflow: hidden;
  background: $color-white;
  
  select {
    display: block;
    width: 100% !important;
    height: 60px !important;
    cursor: pointer;

    box-sizing: border-box;
  
    position: relative !important;
    z-index: 2;
    background: none !important;
    box-shadow: none !important;
    outline: none !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
  }

  &::after{
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icon-cgenial' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 0;
    /* Better Font Rendering =========== */
    text-rendering:optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    content: '\e902';
  
    position: absolute;
    top: 50%;
    right: 10px;
    //margin-top: -16px;
    font-size: 35px !important;
    cursor: pointer;
  }
}

.form-check {
  padding-left: 30px;

  input[type="checkbox"], input[type="radio"] {
    opacity: 0;
    z-index: 1;
    width: 22px;
    height: 22px;
    margin: 0;
    
    + label {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      padding: 0 0 0 5px;
      line-height: 1.3em;
      color: $color-tundora;
      
      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        width: 22px;
        height: 22px;
        left: 0;
        margin-left: -30px;
        border: 1px solid $color-silver-chalice;
        background-color: $color-white;
        -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
        -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
        transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
        cursor: pointer;
      }
    }

    &:disabled {
      + label {
        opacity: 0.65;
        &::before {
          cursor: not-allowed;
        }
        &::after {
          cursor: not-allowed;
        }
      }
    }
  }

  input[type="checkbox"] {
    + label {
      &::before {
        border-radius: 3px;
      }
    }

    &:checked {
      + label {
        &::after {
          /* use !important to prevent issues with browser extensions that change fonts */
          font-family: 'icon-cgenial' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 0;
          /* Better Font Rendering =========== */
          text-rendering:optimizeLegibility;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          
          content: "\e913";
          position: absolute;
          top: 8px;
          left: 2px;
          width: 22px;
          height: 22px;
          margin-left: -30px;
          font-size: 22px;
          color: $color-tangerine;

          cursor: pointer;
        }
      }
    }
  }  

  input[type="radio"] {
    + label {
      &::before {
        border-radius: 50%;
      }
      &::after {
        display: inline-block;
        position: absolute;
        content: " ";
        width: 10px;
        height: 10px;
        left: 6px;
        top: 6px;
        margin-left: -30px;
        border-radius: 50%;
        background-color: $color-tangerine;
        -webkit-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
        -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
        -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
        -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
        transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);

        cursor: pointer;
      }
    }

    &:checked {
      + label {
        &::before {
        
        }
        &::after {
          transform: scale(1, 1);
        }
      }
    }
  } 
}

.form-collection--wrapper {
  .form-collection {
    .select-wrapper {
      display: inline-block;
      width: calc( 100% - 150px);
      margin-bottom: 5px;
    }

    a {
      position: relative;
      top: -30px;
      margin-left: 30px;
    }
  }
  .btn {
    // float: right;
    margin-bottom: $base-spacing;
  }
}

label[for*="profile_inge_form_newsletter"] {
  margin-top: 2*$base-spacing;
}

/* reset password */
.module-reset {
  .form-row {
    margin-bottom: 2em;
  }
  .module-reset .form-row-label,
  .module-reset .form-row-field {
    width: 50%;
  }
  input {
    border: 2px solid #fff;
    background: rgb(232, 240, 254);
    width: 100%;
  }
}