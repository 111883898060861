/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img, iframe, embed {
        display: block;
        margin: auto;
    }
    &.slick-loading {
        img, iframe, embed {
            display: none;
        }
    }

    display: none;

    &.dragging {
        img, iframe, embed {
            pointer-events: none;
        }  
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}



/* Slider */
.slick-loading .slick-list{
    background: url('../images/ajax-loader.gif') center center no-repeat $color-white;
}

/* Arrows */
.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 100%;
    width: 64px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
    z-index: 10;
    &:hover, &:focus {
        outline: none;
        background: transparent;
        color: transparent;
        &:before {
            opacity: 1;
        }
    }
    &.slick-disabled:before {
        opacity: 0.25;
    }
}

.slick-prev:before, .slick-next:before {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icon-cgenial' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 0;
    /* Better Font Rendering =========== */
    text-rendering:optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    font-size: 64px;
    line-height: 1;
    color: $color-tundora;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
    left: 2*$base-spacing;
    [dir="rtl"] & {
        left: auto;
        right: 2*$base-spacing;
    }
    &:before {
        content: "\e903";
        [dir="rtl"] & {
            content: "\e904";
        }
    }
}

.slick-next {
    right: 2*$base-spacing;
    [dir="rtl"] & {
        left: 2*$base-spacing;
        right: auto;
    }
    &:before {
        content: "\e904";
        [dir="rtl"] & {
            content: "\e903";
        }
    }
}

/* Dots */

.slick-dotted .slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: -3*$base-spacing;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    li {
        position: relative;
        display: inline-block;
        height: 10px;
        width: 10px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        button {
            border: 0;
            background: transparent;
            display: block;
            height: 100%;
            width: 100%;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            cursor: pointer;
            &:hover, &:focus {
                outline: none;
                &:before {
                    opacity: 1;
                }
            }
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: "";
                width: 5px;
                height: 5px;
                display: block;
                border-radius: 5px;
                background: $color-tundora;
                opacity: 1;
            }
        }
        &.slick-active button:before {
            background: $color-tangerine;
            opacity: 1;
        }
    }
}
