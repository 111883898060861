.list-cards {
    &>div {
        margin-bottom: 5*$base-spacing;
        .card {
            height: 100%;
        }
        &:nth-child(4n+1) .card.bg-auto {
            @extend .bg-tangerine;

            .item-content {
                .link-more {
                    span i::before {
                        color: $color-tangerine;
                    }
                }
            }
        }
        &:nth-child(4n+2) .card.bg-auto {
            @extend .bg-pelorous;

            .item-content {
                .link-more {
                    span i::before {
                        color: $color-pelorous;
                    }
                }
            }
        }
        &:nth-child(4n+3) .card.bg-auto {
            @extend .bg-la-rioja;

            .item-content {
                .link-more {
                    span i::before {
                        color: $color-la-rioja;
                    }
                }
            }
        }
        &:nth-child(4n+4) .card.bg-auto {
            @extend .bg-tundora;

            .item-content {
                .link-more {
                    &>span {
                        i:before {
                            color: $color-tundora !important;
                        }
                    }
        
                    &:hover,
                    &:focus {
                        color: $color-tangerine !important;
                        &>span {
                            &:before {
                                color: $color-tangerine !important;
                            }
                            i:before {
                                color: $color-white;
                            }
                        }
                    }
                }
            }
        }
        &[class^='col-lg-3'].add-spacing,
        &[class*=' col-lg-3'].add-spacing,
        &[class^='col-xl-3'].add-spacing,
        &[class*=' col-xl-3'].add-spacing {
            padding-bottom: 1*$base-spacing;
            .card {
                margin: 0 1*$base-spacing;
                .item-content {
                    padding: 1*$base-spacing;
                }
            }
        }

        &[class^='col-lg-4'].add-spacing,
        &[class*=' col-lg-4'].add-spacing,
        &[class^='col-xl-4'].add-spacing,
        &[class*=' col-xl-4'].add-spacing {
            padding-bottom: 2*$base-spacing;
            .card {
                margin: 0 2*$base-spacing;
                .item-content {
                    padding: 2*$base-spacing;
                }
            }
        }
    }
}

.card {
    position: relative;
    height: 100%;
    color: $color-white;

    .item-header, .item-footer {
        position: relative;
        width: 100%;
        overflow: hidden;

        > figure, > .figure {
            display: block;
            width: 100%;
            height: 250px;
            margin: 0;
            padding: 0;
            color: $color-tangerine;
            background: no-repeat center center $color-concrete;
            background-size: cover;
            overflow: hidden;

            img {
                width: 100%;
            }
        }

        .item-header-hover, .item-footer-hover {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 250px;
            padding: 3rem;
            text-align: center;
            background: $color-black-alpha;

            transition: all .2s;
        }

        @include media-breakpoint-up(xl) {
            .item-header-hover {
                top: -100%;
            }

            .item-footer-hover {
                top: 100%;
            }
        }

        &:hover {
            .item-header-hover, .item-footer-hover {
                top: 0;
            }    
        }
    }
    &.has-absolute-footer {
        padding-bottom: 250px;

        .item-footer {
            position: absolute;
            height: 250px;
            bottom: 0;
        }
    }
    .item-content {
        padding: 3rem;

        @include media-breakpoint-down(md) {
            [class^="col-md"], [class*="col-md"] {
                & + [class^="col-md"], & + [class*="col-md"] {
                    margin-top: $base-spacing;
                }
            }
        }

        .link-more {
            color: $color-white !important;

            &>span {
                &:before {
                    color: $color-white !important;
                }
            }

            &:hover,
            &:focus {
                color: $color-tundora !important;
                &>span {
                    &:before {
                        color: $color-tundora !important;
                    }
                    i:before {
                        color: $color-white;
                    }
                }
            }
        }

        time.date {
            display: block;
            margin-bottom: 0.5*$base-spacing;

            & + h3 {
                margin-top: 0.5*$base-spacing;
            }
        }

        .thumbnail-video + .topVideo time.date {
            margin-top: 1*$base-spacing;
        }
    }

    .item-more-actions {
        text-align: center;
    }

    &.has-round-picture {
        .item-header {
            overflow: visible;
            padding: 3rem 3rem 3rem 17rem;
            height: auto;
            figure, .figure {
                position: absolute;
                top: -20px;
                left: -35px;
                width: 185px;
                height: 185px;
                border-radius: 50%;
                background: no-repeat center center $color-tundora;
                background-size: cover;
            }
        }
    }
}

.home .card .item-header {
    height: 170px;
}

.home .card .quote {
    
    margin-left: 20px;
}