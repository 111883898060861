#footer {
  .footer_top {
    padding: 4*$base-spacing 0 2*$base-spacing;

    @media (min-width:1200px) {
      padding: 5*$base-spacing 0 5*$base-spacing;  
    }

    .row > * {
      margin-bottom: 2*$base-spacing;  
      @media (min-width:1200px) {
        margin-bottom: 0;  
      }
    }
    .icon-logo {
      display: block;
      margin: 0 auto;
      max-width: 200px;
      max-height: 80px;

      @media (min-width:1200px) {
        margin: 1*$base-spacing 0 0;
      }
    }
    .module-title {
      margin-bottom: 40px;
      color: $color-silver-chalice;
    }

    .module-social {
      .module-title {
        margin-bottom: 60px;
      }  
    }
  }
  .footer_bottom {
    padding: 4*$base-spacing 0 5*$base-spacing;
    color: $color-white;
  }
  .footer_copyright {
    padding: 2*$base-spacing 0;
    
    p {
      margin: 0;
    }
  }
}