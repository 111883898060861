.maintenance {
  text-align: center;

  .website-baseline {
    float: none !important;
    margin: 0 !important;
    text-align: center !important;  
  }

  .container {
    padding: 10em 4em;

    p {
      font-size: 1.5em;
      text-align: center;
    }
  }

  .footer {
    bottom: 0;
    position: absolute;
    width: inherit;
  }
}