.btn {
    max-width: 100%;
    font-family: 'Oswald', 'Arial', sans-serif;
    font-size: 1.5rem;
    line-height: 1.1;
    padding: 15px 20px;
    border-radius: 4px;
    border: none;
    text-transform: uppercase;

    &-xs {
        font-size: 1.4rem;
        padding: 12px 17px;
    }

    &-sm {
        font-size: 1.5rem;
        padding: 12px 17px;
    }

    &-md {
        font-size: 1.5rem;
        padding: 14px 23px;
    }
    
    &-lg {
        font-size: 1.6rem;
        padding: 14px 30px;
    }
    
    &-xl {
        font-size: 3.0rem;
        padding: 21px 52px;
    }

    &.has-select-icon {
        i {
            position: relative;
            bottom: -3px;
            margin-right: 5px;
            font-size: 1.5em;
            line-height: 1px;
        }
    }
}

.link {
    &-more, &-back {
        &>span {
            position: relative;
            top: -2px;

            /* use !important to prevent issues with browser extensions that change fonts */
            font-family: 'icon-cgenial' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 0;
            /* Better Font Rendering =========== */
            text-rendering:optimizeLegibility;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            line-height: 0;
            margin-left: 2px;

            &:before {
                color: $link-color;
            }
            i:before {
                color: $color-white;
            }
        }

        &:hover, &:focus {
            text-decoration: none;

            &>span {
                &:before {
                    color: $link-hover-color;
                }
                i:before {
                    color: $color-white;
                }
            }
        }
    }
}

.switch {
    position: relative;
    display: inline-block;

    .slider {
        position: relative;
        cursor: pointer;
        display: inline-block;
        width: 45px;
        height: 20px;
        //top: 0;
        //left: 0;
        //right: 0;
        //bottom: 0;
        background-color: $color-white;
        transition: .4s;
        border-radius: 20px;
        border: 2px solid #DDD;

        &:before {
            position: absolute;
            content: "";
            height: 20px;
            width: 20px;
            left: -2px;
            bottom: -2px;
            background-color: $color-silver-chalice;
            transition: .4s;
            border-radius: 50%;
        }
    }

    input {
        display: none;

        &:checked {
            &+.slider {
                &:before {
                    transform: translateX(26px);
                    background-color: #ADC50D;
                }
            }
        }
    }

    .label {
        position: relative;
        display: inline-block;
        bottom: 4px;
        text-transform: uppercase;
    }
}