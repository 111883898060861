.js-simple-tooltip {
  background: transparent;
  border: 0;
}

// https://a11y.nicolas-hoffmann.net/simple-tooltip/

// Tooltip hidden by default
.simpletooltip[aria-hidden='true'] {
  display: none;
}

// position relative for containers
.simpletooltip_container {
  position: relative;
}

// tooltip styles
.simpletooltip {
  background: $color-background-primary;
  border-radius: .5em;
  color: $color-primary;
  left: -15em;
  margin-top: 1.5em;
  padding: 1em;
  position: absolute;
  right: auto;
  text-align: left;
  top: 100%;
  width: 30em;
  z-index: 666;
}

.tooltip-info + .simpletooltip {
  background: $bckg-info;
  color: $color-info;
}

// used pseudo-element to make arrows
.simpletooltip::before {
  border: 1em solid transparent;
  border-bottom: 1em solid $color-background-primary;
  content: '';
  height: 0;
  left: 50%;
  margin-left: -1em;
  position: absolute;
  speak: none;
  top: -2em;
  width: 0;
  z-index: 666;
}

.tooltip-info + .simpletooltip::before {
  border-bottom-color: $bckg-info;
}
