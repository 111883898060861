.alert {
  margin-top: 0;
  position: relative;
  ul {
    li {
      & + li {
        margin-top: .5em;  
      }
      a {
        text-decoration: underline;
      }
    }
  }
}