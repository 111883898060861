.body {
    padding-top: 6rem;
    padding-bottom: 6rem;
}

.m {
    &t {
        &-0 {
            margin-top: 0;
        }

        &-1 {
            margin-top: 1*$base-spacing;
        }

        &-2 {
            margin-top: 2*$base-spacing;
        }
    }
    &b {
        &-0 {
            margin-bottom: 0;
        }

        &-1 {
            margin-bottom: 1*$base-spacing;
        }

        &-2 {
            margin-bottom: 2*$base-spacing;
        }
    }
    &l {
        &-0 {
            margin-left: 0;
        }

        &-1 {
            margin-left: 1*$base-spacing;
        }

        &-2 {
            margin-left: 2*$base-spacing;
        }
    }
    &r {
        &-0 {
            margin-right: 0;
        }

        &-1 {
            margin-right: 1*$base-spacing;
        }

        &-2 {
            margin-right: 2*$base-spacing;
        }
    }
}

.p {
    &t {
        &-0 {
            padding-top: 0;
        }

        &-1 {
            padding-top: 1*$base-spacing;
        }

        &-2 {
            padding-top: 2*$base-spacing;
        }
    }
    &b {
        &-0 {
            padding-bottom: 0;
        }

        &-1 {
            padding-bottom: 1*$base-spacing;
        }

        &-2 {
            padding-bottom: 2*$base-spacing;
        }
    }
    &l {
        &-0 {
            padding-left: 0;
        }

        &-1 {
            padding-left: 1*$base-spacing;
        }

        &-2 {
            padding-left: 2*$base-spacing;
        }
    }
    &r {
        &-0 {
            padding-right: 0;
        }

        &-1 {
            padding-right: 1*$base-spacing;
        }

        &-2 {
            padding-right: 2*$base-spacing;
        }
    }
}