.module {
    .module-title {
        margin-bottom: 2*$base-spacing;
    }

    &.pa {
        padding: 60px 30px;
    }

    &-customhtml {
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
        //background-blend-mode: multiply;

        &.has-bg-image {
            position: relative;

            //filter: grayscale(100%);

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                background-color: inherit;
                z-index: 1;
                opacity: 0.85;

                //filter: none;
            }

            .item-content {
                position: relative;
                z-index: 2;
            }
        }

        &.right-block {
            .text-xlarge {
                margin: 0;

                @include media-breakpoint-down(xl) {
                    br {
                        display: none;
                    }
                }
            }
            .btn {
                margin: 2*$base-spacing auto 0;

                @include media-breakpoint-up(lg) {
                    margin: 4*$base-spacing auto 0;
                }

                @include media-breakpoint-up(xl) {
                    margin: 6*$base-spacing auto 0;
                }
            } 
        }

        &.support-us-long {
            .text-xlarge {
                margin: 0;

                &::after {
                    content: "\A";
                    white-space: pre-wrap;
                }

                @include media-breakpoint-up(xl) {
                    &::after {
                        content: "";
                    }
                }
            }
            .btn {
                margin: 2*$base-spacing auto 0;

                @include media-breakpoint-up(lg) {
                    margin: 4*$base-spacing auto 0;
                }

                @include media-breakpoint-up(xl) {
                    margin: -25px 0 0 4*$base-spacing;
                }
            }    
        }
    }
    &-newslist {
        .list-items {
            .item {
                .item-content {
                    .item-title {
                        text-transform: unset;
                        margin-bottom: 2*$base-spacing;
                        a {
                            color: inherit;
                            &:hover,
                            &:focus {
                                color: $link-hover-color;
                            }
                        }
                    }
                    .item-date {
                        display: block;
                        margin-bottom: 2*$base-spacing;
                        color: inherit;
                    }
                    .item-desc {
                        display: block;
                        margin-bottom: 2*$base-spacing;
                        color: inherit;
                    }
                }
            }
        }
    }

    &-testimonialslist {
        .list-items {
            .item {
                .item-content {
                    .item-title {
                        color: inherit;
                    }
                    .item-occupation {
                        display: block;
                        margin-bottom: 2*$base-spacing;
                        color: inherit;
                    }
                    .item-desc {
                        display: block;
                        margin-bottom: 2*$base-spacing;
                        color: inherit;
                    }
                }
            }
        }
    }

    &-newsletter {
        form .input-group-append > div {
            display: inline;
        }
    }

    &-mappee {
        position: relative;

        .search_form {
            margin-top: $base-spacing;
        }

        .operations-list {
            margin-top: -4*$base-spacing;

            .card.mappee-item {
                padding-bottom: 10*$base-spacing;

                .item-content {
                    padding-bottom: 0;    
                }

                .header {
                    @include clearfix();
                    margin-bottom: 3*$base-spacing;

                    .index {
                        float: left;
                        display: flex;
                        margin: 0;
                        width: 64px;
                        height: 64px;
                        border-radius: 50%;
                        color: $color-la-rioja;
                        font: bold 3.2rem 'Oswald', 'Arial', sans-serif;
                        text-transform: uppercase;
                        letter-spacing: 2.4px;
                        background: $color-white;
                        text-align: center;
                        align-items: center;
    
                        span {
                            width: 100%;
                        }
                    }
    
                    .date {
                        display: inline-block;

                        margin: 20px 0 0 $base-spacing;
                        font-weight: normal;
                    }

                    .category-pee {
                        display: block;
                        width: 80px;
                        position: absolute;
                        top: 3rem;
                        right: 3rem;
                    }
                }

                .learn_more {
                    .link {
                        color: $color-tundora !important;

                        &:hover, &:focus {
                            color: $color-alabaster !important;
                        }
                    }
                }

                .footer {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    padding: 1*$base-spacing 3*$base-spacing 3*$base-spacing;
                }

                input[name="operation[]"] {
                    & + .btn {
                        i {
                            position: relative;
                            bottom: -3px;
                            margin-right: 5px;
                            font-size: 1.5em;
                            line-height: 1px;
                        }
                    }
        
                    &:checked {
                        & + .btn {
                            i {
                                
                            }
                        }
                    }
                }
            }
        }
    }

    &-partnerslist {
        &.layout-cols {
            @include media-breakpoint-down(lg) {
                [class^="col-"], [class*=" col-"] {
                    margin-bottom: 1*$base-spacing;
                }
            }
        }
    }

    &-inview {
        &-login {
            &.login-col-left, &.login-col-right {
                height: 100%;
                
                // display: flex;
                // align-items: center;

                // > div {
                //     width: 100%;
                // }
            }

            &.login-col-right {

                h2, .h2,
                h3, .h3 {
                    font-size: 2.8rem;
                }

                p:first-child {
                    margin-top: 0;
                }

                p {
                    font-size: 2.4rem;
                }
            }
        }

        &-user-preferences {
            margin-top: 6*$base-spacing;
            input[name="preference[]"] {
                & + .btn {
                    i {
                        position: relative;
                        bottom: -3px;
                        margin-right: 5px;
                        font-size: 1.5em;
                        line-height: 1px;
                    }
                }
    
                &:checked {
                    & + .btn {
                        i {
                            
                        }
                    }
                }
            }
        }
    }
}

.grecaptcha-badge {
    display: none;
}

.gmap {
    display: block;
    width: 100%;
    height: 600px;
}

.sidebar {
    @include media-breakpoint-down(lg) {
        margin-top: 6*$base-spacing;
    }

    .module {
        margin-bottom: 2*$base-spacing;
    }
}