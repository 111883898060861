$icomoon-font-path: "../fonts/cgenial" !default;

$icon-cgenial-simple: "\e908";
$icon-flickr-color-path1: "\e909";
$icon-flickr-color-path2: "\e90a";
$icon-cgenial-triple: "\e90b";
$icon-triple-color-path1: "\e90c";
$icon-triple-color-path2: "\e90d";
$icon-triple-color-path3: "\e90e";
$icon-youtube: "\e90f";
$icon-logout: "\e910";
$icon-arrow-end-left: "\e911";
$icon-arrow-end-right: "\e912";
$icon-checkmark: "\e913";
$icon-filters: "\e906";
$icon-search: "\e907";
$icon-envelop: "\e900";
$icon-tick: "\e901";
$icon-arrow-down: "\e902";
$icon-arrow-left: "\e903";
$icon-arrow-right: "\e904";
$icon-arrow-top: "\e905";
$icon-printer: "\e954";
$icon-upload: "\e9c8";
$icon-info: "\ea0c";
$icon-stop: "\ea1e";
$icon-google-plus: "\ea8b";
$icon-facebook: "\ea90";
$icon-twitter: "\ea96";
$icon-flickr: "\eaa3";
$icon-linkedin: "\eaca";
$icon-instagram: "\e914";

@font-face {
    font-family: 'icon-cgenial';
    src : url('#{$icomoon-font-path}/icon-cgenial.eot?ipoavn');
    src : url('#{$icomoon-font-path}/icon-cgenial.eot?ipoavn#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/icon-cgenial.ttf?ipoavn') format('truetype'),
    url('#{$icomoon-font-path}/icon-cgenial.woff?ipoavn') format('woff'),
    url('#{$icomoon-font-path}/icon-cgenial.svg?ipoavn#icon-cgenial') format('svg');
    font-weight: normal;
    font-style: normal;
}

.icon-cgenial {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icon-cgenial' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    text-rendering:optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-cgenial.icon-cgenial-simple {
    &:before {
        content: $icon-cgenial-simple;
    }
}

.icon-cgenial.icon-flickr-color .path1 {
    &:before {
        content: $icon-flickr-color-path1;
        color: rgb(0, 99, 220);
    }
}

.icon-cgenial.icon-flickr-color .path2 {
    &:before {
        content: $icon-flickr-color-path2;
        margin-left: -1em;
        color: rgb(255, 0, 132);
    }
}

.icon-cgenial.icon-cgenial-triple {
    &:before {
        content: $icon-cgenial-triple;
    }
}

.icon-cgenial.icon-triple-color .path1 {
    &:before {
        content: $icon-triple-color-path1;
        color: $color-pelorous;
    }
}

.icon-cgenial.icon-triple-color .path2 {
    &:before {
        content: $icon-triple-color-path2;
        margin-left: -1em;
        color: $color-tangerine;
    }
}

.icon-cgenial.icon-triple-color .path3 {
    &:before {
        content: $icon-triple-color-path3;
        margin-left: -1em;
        color: $color-la-rioja;
    }
}

.icon-cgenial.icon-youtube {
    &:before {
        content: $icon-youtube;
    }
}

.icon-cgenial.icon-logout {
    &:before {
        content: $icon-logout;
    }
}

.icon-cgenial.icon-arrow-end-left {
    &:before {
        content: $icon-arrow-end-left;
    }
}

.icon-cgenial.icon-arrow-end-right {
    &:before {
        content: $icon-arrow-end-right;
    }
}

.icon-cgenial.icon-checkmark {
    &:before {
        content: $icon-checkmark;
    }
}

.icon-cgenial.icon-filters {
    &:before {
        content: $icon-filters;
    }
}

.icon-cgenial.icon-search {
    &:before {
        content: $icon-search;
    }
}

.icon-cgenial.icon-envelop {
    &:before {
        content: $icon-envelop;
    }
}

.icon-cgenial.icon-tick {
    &:before {
        content: $icon-tick;
    }
}

.icon-cgenial.icon-arrow-down {
    &:before {
        content: $icon-arrow-down;
    }
}

.icon-cgenial.icon-arrow-left {
    &:before {
        content: $icon-arrow-left;
    }
}

.icon-cgenial.icon-arrow-right {
    &:before {
        content: $icon-arrow-right;
    }
}

.icon-cgenial.icon-arrow-top {
    &:before {
        content: $icon-arrow-top;
    }
}

.icon-cgenial.icon-printer {
    &:before {
        content: $icon-printer;
    }
}

.icon-cgenial.icon-upload {
    &:before {
        content: $icon-upload;
    }
}

.icon-cgenial.icon-info {
    &:before {
        content: $icon-info;
    }
}

.icon-cgenial.icon-stop {
    &:before {
        content: $icon-stop;
    }
}

.icon-cgenial.icon-google-plus {
    &:before {
        content: $icon-google-plus;
    }
}

.icon-cgenial.icon-facebook {
    &:before {
        content: $icon-facebook;
    }
}

.icon-cgenial.icon-twitter {
    &:before {
        content: $icon-twitter;
    }
}

.icon-cgenial.icon-flickr {
    &:before {
        content: $icon-flickr;
    }
}

.icon-cgenial.icon-linkedin {
    &:before {
        content: $icon-linkedin;
    }
}

.icon-cgenial.icon-instagram {
    &:before {
      content: $icon-instagram; 
    }
  }


.icon-legacy:not(.icon-logo) {
    fill: currentColor;
    height: 1.6rem;
    width: 1.6rem;
}