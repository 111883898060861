// This rule generated by Transfonter (https://transfonter.org) on March 21, 2017
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: normal;
  src: local('Oswald Regular'), local('Oswald-Regular'),
      url('../fonts/oswald/Oswald-Regular.woff2') format('woff2'),
      url('../fonts/oswald/Oswald-Regular.woff') format('woff');
}

body {
  font-family: 'Arial', sans-serif; 
  font-size: $font-size-default;
  font-weight: 400; 
  line-height: $line-height-default;
  color: $color-tundora;
}

h1,
.h1 {
  font-family: 'Oswald', 'Arial', sans-serif;
  font-size: $font-size-h1;
  font-weight: 600;
  line-height: $line-height-h1;
  text-transform: uppercase;
  color: $color-tundora;
}

h2,
h3,
h4,
h5,
h6,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Oswald', 'Arial', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  color: $color-tundora;
}

h2,
.h2 {
  font-size: $font-size-h2;
  line-height: $line-height-h2;
}

h3,
.h3 {
  font-size: $font-size-h3;
  line-height: $line-height-h3;
}

h4,
.h4 {
  font-size: $font-size-h4;
  line-height: $line-height-h4;
}

h5,
.h5 {
  font-size: $font-size-h5;
  line-height: $line-height-h5;
}

h6,
.h6 {
  font-size: $font-size-h6;
  line-height: $line-height-h6;
}

p,
.p {
  font-size: $font-size-p;
  font-weight: 400;
  line-height: $line-height-p;
  color: $color-tundora;
}

.has-color-bubbles {
  color: $color-tundora !important;
}

.text {
  &-leading {
    font-size: $font-size-leading;
    line-height: $line-height-leading;
  }

  &-small {
    font-size: $font-size-small;
    line-height: $line-height-small;
  }

  &-large {
    font-size: $font-size-large;
    line-height: $line-height-large;
  }

  &-xlarge {
    font-size: $font-size-xlarge;
    line-height: $line-height-xlarge;
  }

  &-no-transform {
    text-transform: none !important;
  }

  &-thin {
    font-weight: 300 !important;
  }

  &-normal {
    font-weight: 400 !important;
  }

  &-bold {
    font-weight: 600 !important;
  }
}

strong, .strong {
  font-weight: 600;
}


a {
  color: $link-color;
  &:hover, &:focus {
      color: $link-hover-color;
  }
}

h1,
h2,
h3,
h4 {
  a {
    color: inherit;
    text-decoration: none;

    &:hover, &:focus {
      text-decoration: none;
    }
  }
}

.body, .before_body, .after_body, #tinymce { 
  ul:not(.no-style):not(.slick-dots) {
    li {
      &::before {
        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: 'icon-cgenial' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 0;
        /* Better Font Rendering =========== */
        text-rendering:optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        
        content: $icon-cgenial-simple;
        position: relative;
        top: -4px;
        font-size: 6px;
        margin-right: 0.6rem;

        color: $color-tangerine;
      }
    }
  }

  ol:not(.no-style) {
    list-style: none; 
    counter-reset: item;
    
    li {
      counter-increment: item;

      &::before {
        content: counter(item) "."; 
        display: inline-block; 
        width: 1em; 
        margin-left: -3.5rem;
        margin-right: 0.6rem; 
        text-align: right; 
        //direction: rtl;

        color: $color-tangerine;
      }
    }
  }
}