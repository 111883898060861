body {
  &.home {
    .maincontent {
      .before_body {}
      .body {
        @extend .bg-alabaster;
        .module {
          text-align: center;
          .module-title {
            @extend .h2;
            margin: 0 0 4*$base-spacing;
          }
          p {
            font-size: 2.8rem;
          }
        }
      }
      .after_body {
        .module {
          &:nth-child(odd) {
            @extend .bg-white;
            @extend .bg-white.tseparator;
          }
          &:nth-child(even) {
            @extend .bg-alabaster;
            @extend .bg-alabaster.tseparator;
          }
          &:last-child {
            padding-bottom: 5*$base-spacing;
          }
          &-inview-index {
            padding-top: 355px !important;
            padding-bottom: 5*$base-spacing;

            .col-xl-4 {
              margin-bottom: 2*$base-spacing;
              
              @include media-breakpoint-up(xl) {
                margin-bottom: 0;
                &:nth-child(1) {
                  padding-right: 4*$base-spacing;
                }
                &:nth-child(2) {
                  padding-left: 4*$base-spacing;
                }
              }
            }

            .card {
              h2, h3 {
                @extend .text-thin;
                font-size: 2.8rem;
                line-height: 1.2;
                margin: 0;
              }
            }
          }
          .module-title {
            margin: 0 0 5*$base-spacing;
          }
          .module-more-actions {
            margin: 5*$base-spacing 0 0;
            .btn {
              font-size: $font-size-h6;
            }
          }
        }
      }
    }
  }
}

.bubbles {
  text-align: center;
  margin-top: 4*$base-spacing;
  .row>* {
    height: 230px;
    padding-top: 56px;
    margin-bottom: 2*$base-spacing;

    p {
        color: inherit;
        font-family: 'Oswald', 'Arial', sans-serif;
        font-size: 1.8rem;
        line-height: 1.1;
        font-weight: 700;
        letter-spacing: 2.1px;
        text-transform: uppercase;

        strong {
            display: block;
            font-size: 5rem;
            font-weight: 400;
            letter-spacing: 3.75px;
        }
    }

    body.home & svg {
      fill: currentColor;
      width: 150px; 
    }
    
    body.home & svg + p {
      margin-top: 0;
    }
  }
}